// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-aktualneoferty-jsx": () => import("./../../../src/pages/aktualneoferty.jsx" /* webpackChunkName: "component---src-pages-aktualneoferty-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-home-jsx": () => import("./../../../src/pages/home.jsx" /* webpackChunkName: "component---src-pages-home-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-kariera-jsx": () => import("./../../../src/pages/kariera.jsx" /* webpackChunkName: "component---src-pages-kariera-jsx" */),
  "component---src-pages-onas-jsx": () => import("./../../../src/pages/onas.jsx" /* webpackChunkName: "component---src-pages-onas-jsx" */),
  "component---src-templates-real-estate-real-estate-jsx": () => import("./../../../src/templates/RealEstate/RealEstate.jsx" /* webpackChunkName: "component---src-templates-real-estate-real-estate-jsx" */)
}

